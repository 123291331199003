body {
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #d5d5d5;
}

.sc-dmctIk,
.sc-dmXXqC {
  border: 1px solid rgba(180, 180, 180, 0.241) !important;
  border-radius: 5px !important;
}

.sc-csuSiG {
  font-family: "Public Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #212b36 !important;
}

.sc-hLBbgP,
.sc-hLQRIN {
  font-family: "Public Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #212b36 !important;
}

.showlist {
  padding: 6px 16px !important;
  font-family: "Public Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-align: center !important;
  background-color: #00ab55 !important;
  color: white !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sc-dmctIk::-webkit-scrollbar,
.sc-dmXXqC::-webkit-scrollbar {
  width: 5px !important;
  height: 10px !important;
}

.sc-dmctIk::-webkit-scrollbar-track,
.sc-dmXXqC::-webkit-scrollbar-track {
  background-color: white !important;
}

.sc-dmctIk::-webkit-scrollbar-thumb,
.sc-dmXXqC::-webkit-scrollbar-thumb {
  background-color: #78787850 !important;
}

.sc-dmctIk::-webkit-scrollbar-thumb:hover,
.sc-dmXXqC::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}

/* ZOOM */
#root,
#meetingSDKElement {
  width: 100vw !important;
  height: 100vh !important;
  overflow-x: hidden !important;
}

#zmmtg-root {
  height: 0% !important;
}

.react-draggable {
  transform: none !important;
  width: 100vw !important;
  border-radius: 0px !important;
}

.zmwebsdk-makeStyles-singleView-7 {
  padding: 0 !important;
  border-radius: 0px !important;
  height: 100vh !important;
}

.zmwebsdk-makeStyles-root-28 {
  width: 100vw !important;
  height: 100vh !important;
  border-radius: 0px !important;
}

.zmwebsdk-makeStyles-panel-104 {
  width: 408px !important;
}

.zmwebsdk-makeStyles-root-58 {
  position: fixed !important;
  width: 100vw !important;
  bottom: 0 !important;
  padding: 30px 0 !important;
}

.zmwebsdk-makeStyles-root-30 {
  box-shadow: none !important;
}

.zmwebsdk-MuiPaper-rounded {
  border-radius: 0px !important;
}

#suspension-view-tabpanel-active {
  height: 80vh !important;
}

.zmwebsdk-MuiPaper-elevation1 {
  /* height: 100% !important; */
  box-shadow: none !important;
  width: 100% !important;
}

.zmwebsdk-makeStyles-root-195 {
  width: 100% !important;
  height: 100% !important;
}

.zmwebsdk-makeStyles-paper-395 {
  display: flex !important;
  justify-content: center !important;
  background-color: transparent !important;
}

.zmwebsdk-makeStyles-assignMode-389 {
  justify-content: center;
}

.zmwebsdk-makeStyles-root-177 {
  width: 100vw !important;
}

.zmwebsdk-makeStyles-wrap-55 {
  max-width: 100% !important;
  max-height: 100% !important;
}

.cross:hover {
  background-color: transparent !important;
}

.profileBg {
  background-color: transparent !important;
}

.profileBg:hover {
  background-color: rgb(244, 246, 248) !important;
}

.zone {
  background-color: transparent !important;
}

.zone:hover {
  background-color: white !important;
}

.hovButton:hover {
  background-color: white !important;
}

.hgByPU div:first-child {
  font-family: "Public Sans", sans-serif !important;
}

.rdt_TableHeadRow,
.rdt_TableRow {
  color: #212b36 !important;
  font-family: Public Sans, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 22px !important;
}


